"use strict";

export class SystemError extends Error {
	constructor (error) {
		super(error.message || error.name);

		this.name = error.name;
		this.code = error.code;
		this.status = error.status;
		this.originalError = error.originalError;
	}
}

export class LocalFakeError extends Error {
	constructor (code, data) {
		super(`Local fake error: ${code}`);

		this.status = code;
		this.error = {
			data: { error: { code, details: data } }
		};
	}
}
