import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

import { environment } from '../../../environments/environment';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { SpeakerService } from '../../common/services/speaker.service';

@Component({
	selector: 'app-repeat',
	templateUrl: './repeat.component.html',
	styleUrls: ['./repeat.component.css']
})
export class RepeatComponent implements OnInit {
	constructor (
		private metaInfo: MetaInfoService,
		private userModel: UserModelService,
		private speaker: SpeakerService,
		private router: Router,
	) {
	}

	_finish = false;
	words;
	resultData = { };
	flip = false;
	index = 0;
	doneArr = [];
	resetArr = [];
	lockNext = false;
	lockReset = false;
	restRepeat = 0;
	vibrationOk = [50];
	vibrationFalse = [30, 80, 40];
	vibrationClick = 30;

	audioCrash;
	audioSuccess;

	title = 'Повтореня слів';

	@HostListener('document:keydown', ['$event'])
	handleDeleteKeyboardEvent (event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowUp':
			case 'ArrowDown':
				this.flipCard();
				break;
			case 'ArrowLeft':
				this.reset();
				break;
			case 'ArrowRight':
				this.nextStep();
				break;
		}
	}

	async ngOnInit (): Promise<void> {
		try {
			this.metaInfo.setData({
				leftMenu: null,
				rightMenu: null,
				title: this.title,
			});

			this.audioCrash = new Audio(environment.frontUrl + '/assets/sounds/crash.mp3');
			this.audioSuccess = new Audio(environment.frontUrl + '/assets/sounds/success.mp3');

			await this.getWordsToRepeat();
		} catch (e) {
			console.log('repeat err', e);
		}
	}

	flipCard () {
		this.vibrate(this.vibrationClick);
		this.flip = !this.flip;
	}

	async nextStep () {
		if (!this.words.length) {
			this.vibrate(this.vibrationClick);

			return this.router.navigate(['study']);
		}
		if (this._finish) {
			this.vibrate(this.vibrationClick);

			return this.getWordsToRepeat();
		}
		if (this.lockNext) {
			return;
		}
		this.lockNext = true;
		this.flip = false;
		this.doneArr.push(this.words[this.index].text);
		this.collectFinishData(this.words[this.index]);
		if (this.index < this.words.length) {
			this.vibrate(this.vibrationClick);
			this.audioSuccess.play();
		}
		await new Promise(r => setTimeout(r, 400));
		this.index++;
		if (this.index < this.words.length) {
			this.saySmt(this.words[this.index].text);
		} else {
			this.finish();
		}
		this.lockNext = false;
	}

	async reset () {
		if (!this.words.length || this._finish) {
			this.vibrate(this.vibrationClick);

			return this.router.navigate(['dash']);
		}

		if (this.lockReset) {
			return;
		}
		this.lockReset = true;
		this.flip = false;
		this.resetArr.push(this.words[this.index].text);
		this.collectFinishData(this.words[this.index]);
		if (this.index < this.words.length) {
			this.vibrate(this.vibrationClick);
			this.audioCrash.play();
		}
		await new Promise(r => setTimeout(r, 400));
		this.index++;
		if (this.index < this.words.length) {
			this.saySmt(this.words[this.index].text);
		} else {
			if (this._finish) {
				this.vibrate(this.vibrationClick);
				await this.router.navigate(['dash']);
			} else {
				this.finish();
			}
		}
		this.lockReset = false;
	}

	async finish () {
		this._finish = true;
		try {
			this.metaInfo.showPreloader(true);
			await this.userModel.saveLocalRepeatWords({ done: this.doneArr, reset: this.resetArr });
			let res  = await this.userModel.getDashLocalWords();
			this.restRepeat = res.repeat;
		} catch (e) {
			this.metaInfo.showError(e);
		}
		this.metaInfo.showPreloader(false);
	}

	saySmt (text, event = null) {
		this.speaker.saySmt(text);
		if (event) {
			event.stopPropagation();
		}
	}

	async getWordsToRepeat () {
		try {
			this._finish = false;
			this.words = null;
			this.flip = false;
			this.index = 0;
			this.doneArr = [];
			this.resetArr = [];

			this.metaInfo.showPreloader(true);
			this.words = (await this.userModel.getLocalRepeatWords()).map(w => {
				w.transcription = w.transcription ? w.transcription.split('|')[0] : w.transcription;

				return w;
			});

			this.metaInfo.showPreloader(false);
			if (this.words && this.words.length > 0) {
				this.saySmt(this.words[this.index].text);
			}
		} catch (err) {
			console.log('=-=- repeat error', JSON.stringify(err));
			this.metaInfo.showError(err);
		}
	}

	vibrate (pattern: number | Array<number>): void {
		if (window.navigator.vibrate) {
			window.navigator.vibrate(pattern);
		}
	}

	collectFinishData (word) {
		let progress: number;
		let nextStep: number;
		let icon: string;
		let time: string;

		if (this.doneArr.includes(word.text)) {
			progress = word.step * 10;
			nextStep = word.step + 1;
			if (word.step === 10) {
				icon = 'task_alt';
			} else {
				icon = 'arrow_circle_up';
			}
		} else {
			progress = word.step === 1 ? 0 : 10;
			nextStep = 0;
			icon = 'arrow_circle_down';
		}

		if (word.step >= 10) {
			time = '!!! done !!!';
		} else {
			time = DateTime.fromMillis(this.userModel.getNextCheckTime(nextStep))
				.toFormat('yyyy/MM/dd');
		}

		this.resultData[word.text] = {
			progress,
			time,
			icon
		};
	}
}
