<mat-card *ngIf="!_finish && words && !words.length" class="float-card main-card">
	<mat-card-content>
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">На повторені немає слів</mat-card-title>
			<!--				<mat-card-subtitle> </mat-card-subtitle>-->
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">task_alt</mat-icon>
		</div>
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button routerLink="/dash" mat-stroked-button>Головна</button>
		<button routerLink="/study" mat-stroked-button>Вчити слова</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
<mat-card *ngIf="!_finish && words && words.length" class="float-card main-card">
	<mat-card-content>
		<div class="card center" (click)="flipCard()" [ngClass]="flip ?  'flip-card' : 'card'">
			<mat-card class="front large-text">
				{{words[index].text}}
				<button mat-fab color="primary" (click)="saySmt(this.words[this.index].text, $event)"
						aria-label="Say word" style="margin-top: 20px">
					<mat-icon>volume_down</mat-icon>
				</button>
				<!--		<img src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="">-->
			</mat-card>
			<mat-card class="back inner-card">
				<mat-card-header class="title-card text-center">
					<mat-card-title class="large-text">{{words[index].translate}}</mat-card-title>
					<mat-card-subtitle>/{{words[index].transcription}}/</mat-card-subtitle>
				</mat-card-header>
				<mat-divider class="inner-divider"></mat-divider>
				<div class="examples">
					<p *ngFor="let example of words[index].examples">
						➦ {{example.en}}<br>
						➥ {{example.org}}
					</p>
				</div>
			</mat-card>
		</div>
	</mat-card-content>
	<div *ngFor="let w of words; let i = index;">
		<mat-card-actions class="center-block" *ngIf="i===index">
			<button mat-stroked-button (click)="reset()">⬅️ Не памʼятаю</button>
			<button mat-stroked-button (click)="nextStep()">Памʼятаю ➡️</button>
		</mat-card-actions>
	</div>
	<mat-card-footer>
		<mat-progress-bar mode="determinate" value="{{1/words.length*index*100}}"></mat-progress-bar>
	</mat-card-footer>
</mat-card>

<mat-card *ngIf="_finish" class="float-card main-card">
	<mat-card-content>
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Повторення завершене</mat-card-title>
			<mat-card-subtitle>Залишилось повторити: {{restRepeat}}</mat-card-subtitle>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div>
			<mat-action-list>
				<mat-list-item *ngFor="let word of words" routerLink="/dictionary/{{word.text}}">
					<mat-icon matListItemIcon>{{this.resultData[word.text].icon}}</mat-icon>
					<div class="text-en" matListItemTitle style="font-weight: bolder">
						<span>{{word.text}}</span>
					</div>
					<div matListItemMeta>
						{{this.resultData[word.text].time}}
					</div>
					<mat-progress-bar mode="determinate" value="{{this.resultData[word.text].progress}}"></mat-progress-bar>
				</mat-list-item>
			</mat-action-list>
		</div>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button mat-stroked-button routerLink="/dash">⬅️ Головна</button>
		<button (click)="getWordsToRepeat()" mat-stroked-button>Повторити ще ➡️</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
