import Dexie, { Table } from 'dexie';

export interface Word {
	text: string;
	transcription: string;
	translate: string;
	examples?: Array<{ en: string | null, org: string | null, s?: number }>;
	wordId?: number;
	status?: number;
	step?: number;
	next_check_at?: number;
	added_at?: number;
	updated_at?: number;
	version?: number;
	localSaved?: boolean;
}

export interface Request {
	id?: number;
	name: string;
	data?: Array<any>;
}

export interface Option {
	name: string;
	data?: any;
	version?: number;
}

export const getNextCheckTime = (step: number | string) => {
	let hours;
	switch (+step) {
		case 0:
		case 1:
			hours = 24 / 3;
			break;
		case 2:
			hours = 1 * 24;
			break;
		case 3:
			hours = 2 * 24;
			break;
		case 4:
			hours = 5 * 24;
			break;
		case 5:
			hours = 7 * 24;
			break;
		case 6:
			hours = 14 * 24;
			break;
		case 7:
			hours = 30 * 24;
			break;
		case 8:
		case 9:
		case 10:
			hours = 120 * 24;
			break;
		default:
			throw new Error('Invalid step value');
	}

	return Date.now() + hours * 60 * 60 * 1000;
};

export class AppDB extends Dexie {
	words!: Table<Word, number>;
	requests!: Table<Request, number>;
	options!: Table<Option, number>;

	constructor () {
		super('word-app-db');

		this.version(3)
		.stores({
			words: '++,&text,status,added_at,updated_at',
			requests: '++id',
			options: '++id,&name'
		});
	}
}

export const db = new AppDB();

db.open();

db.words.hook('updating', (modifications, primKey, obj, transaction) => {
	// hack to avoid ignore same version in time of unversion update
	if (modifications['versionMod']) {
		modifications['version'] = modifications['versionMod'];
		delete modifications['versionMod'];
	}

	// You may use transaction to do additional database operations.
	// You may not do any modifications on any of the given arguments.
	// You may set this.onsuccess = function (updatedObj){} when update operation completes.
	// You may set this.onerror = callback if update operation fails.
	// If you want to make additional modifications,
	// return another modifications object
	// containing the additional or overridden modifications to make. Any returned
	// object will be merged to the given modifications object.
	if (modifications['version'] === undefined) {
		modifications['version'] = obj.version ? obj.version + 1 : 1;
	} else {
		let version = parseInt(modifications['version'], 10);
		modifications['version'] = isNaN(version) ? obj.version : version;
	}

	if (modifications['updated_at'] === undefined) {
		modifications['updated_at'] = Date.now();
	}

	if (obj['added_at'] === undefined) {
		modifications['added_at'] = Date.now();
	}

	return modifications;
});

db.words.hook('creating', (primKey, obj, transaction) => {
	if (obj['version'] === undefined) {
		obj['version'] = 1;
	}

	if (obj['status'] === undefined) {
		obj['status'] = 1;
	}

	if (obj['updated_at'] === undefined) {
		obj['updated_at'] = Date.now();
	}

	if (obj['added_at'] === undefined) {
		obj['added_at'] = Date.now();
	}
});
